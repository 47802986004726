/*Kleuren
Licht Blauwgroen  - #E5F0F0 - rgba(180,5,94,1)
Midden Blauwgroen - #CBE1E2 - rgba(183,10,89,1)  
Donker Blauwgroen - #81B3B7 - rgba(184,30,72,1)
Rusty Orange - #CF4B13 - rgba(18,91,81,1)
Donkerblauw - #2C3347 - rgba(224,38,28,1)  
*/
/* Dit is CSS voor het ontwerp, geen productie-CSS */



/*home uitgelicht*/
.uitgelicht a  {
	color: #2C3347;
	text-decoration: none;
}

.uitgelicht .meer {
	color:#CF4B13;
	text-decoration: underline
}

.uitgelicht a:hover .meer, .uitgelicht a:focus .meer{
	color: #993911;
}


.resultaten.uitgebreid a:hover+div a, .resultaten.uitgebreid a:focus+div a {
	color: #993911;
} 

/*icoontjes bij beeldbank na resultaten*/
.gezocht img {
	max-width: 2rem;
    margin-left: 1rem;
    float: right;
    background: #2C3347;
}

.gezocht img:hover, .gezocht img:focus {
    background: #CF4B13;
}

.gezocht .selected {
	background: #CF4B13;
}

/*beeldbank 1*/
.beeldbank.resultaten li:hover, .beeldbank.resultaten li:focus{
	background: #81B3B7;
	transition: .2s ease-in-out;
	}


.overzicht.personen.beeldbank td:nth-of-type(3) {
    display: block;
    font-weight: normal; 
    }
    
.overzicht.personen.beeldbank thead th {
	white-space: nowrap
}

.overzicht.personen td:last-child {
    text-align: left;
    }
    
.beeldbank.resultaten ul li {
  background: #CBE1E2;   
  break-inside: avoid;
}


.beeldbank.resultaten ul li h2{
    font-size: 1rem;
    color: #2C3347;
    padding: 0 5rem 1rem 1rem;
   }

.beeldbank.resultaten li a {
	padding-right: 0;
  background: none;
  text-decoration: none;
}

.beeldbank.resultaten li div {
	position: relative;
	}
	
.deelcollectie {
	position: absolute;
	max-width: 4rem;
	float: right;
	top: -.5rem;
  right: 0.5rem;
  }




/*beeldbanklijst - beeldbank 2*/

.beeldbanklijst.resultaten li h2 a	{
    padding-right: 1rem;
    background: rgba(0,0,0,0) url(./img/icon-arrow-orange.svg) no-repeat top .6rem right / .6rem auto;
    display: inline-block;
    }
.icooninfoto{
  padding-left:0;
}
.icooninfoto li {
  display: block;
  overflow: hidden;
  border-bottom: 2px solid #CBE1E2;
  margin-top: 1rem;
} 
  
.icooninfoto li a {
  display: block;
  position: relative;
  width:100%;
  height:auto;

  padding:0;
  float:left;
}
.icooninfoto li div {
  float: left;
  width:100%;
  margin-left: 0rem;
}

.icooninfoto li:hover a{
  color: #993911
  }


.icooninfoto div h2 a{
  width: auto;
  min-width: inherit;
  font-size:1.25rem;
  background: rgba(0,0,0,0) url(./img/icon-arrow-orange.svg) no-repeat top .5rem right / .6rem auto;
  display: inline;
  padding-right: 1rem;
  margin-bottom: .5rem;
  float:none;
}

.icooninfoto li a .deelcollectielijst {
  position:absolute;
  bottom:16px;
  right:0;
  margin:0;
  width:4rem;
  background-color: #CBE1E2;
}



.icooninfoto table tr {
  border: none;
}
@media screen and (min-width: 35rem){


/*beeldbank*/	
.beeldbank.resultaten ul{
	columns:2 10vw;
}

.beeldbank.resultaten ul li {
  background: #CBE1E2;   
  break-inside: avoid;
}


.beeldbank.resultaten ul li h2{
    font-size: 1rem;
    color: #2C3347;
    padding: 0 5rem 1rem 1rem;
   }

.beeldbank.resultaten li a {
	padding-right: 0;
    background: none;
    text-decoration: none;
}

.beeldbank.resultaten li div {
	position: relative;
	}
	
.deelcollectie {
	position: absolute;
	max-width: 4rem;
	float: right;
	top: .1rem;
   right: 0.5rem;
   }
.icooninfoto li a {
  width:100%;
  
}
.icooninfoto li div {
  width:100%;
}

}
@media screen and (min-width: 42.5rem){


/*beeldbank*/	
.beeldbank.resultaten .wrap {
	padding-right: 0;
}

.beeldbank.resultaten ul{
	columns:3 10vw;
		
}
	

.icooninfoto li a {
  width:40%;
  
}
.icooninfoto li div {
  width:55%;
  margin-left: 5%;
}
}    

/*tweakpoint beeldbank*/
@media screen and (min-width: 56rem){
  .icooninfoto li a {
  width:30%;
  
}
.icooninfoto li div {
  width:60%;
  margin-left: 1rem;
}
} 
  
  

@media screen and (min-width: 59rem){
 
 .menu .hoofd .active{
	border-bottom: 10px #CBE1E2 solid;
	margin-bottom: -8px;
	
	}

.icooninfoto li a {
  width:30%;
  height:auto;
  max-width: 200px; }
  
  .icooninfoto li div {
  width:55%;
  margin-left: 5%;
}


}


/* Stijlen alleen voor de uitlevering */
.notitie {
	position: absolute;
	top: 0;
	right: 1rem;
	z-index: 100;
	margin: 0;
	padding: 0;
	border-radius: 0 0 .4rem .4rem;
	background: #fb0;
	text-align: right;
	border: 0;
	z-index: 99999;
	font-size: 16px;
}

.notitie a {
	display: block;
	padding: 0 1.5rem .5rem;
	color: #000;
	text-decoration: none;
	border: 0;
}
.notitie li a{
  display: inline;
  padding:0;
  text-decoration: underline;
}

.notitie #content {
	display: none;
	height: auto;
	padding: 0 1.5rem 1em;
}
.notitie a:focus #content,
.notitie:hover {
	width: 80%;
	height: auto;
}

.notitie a:focus #content,
.notitie:hover #content {
	display: block;
	text-align: left;
}

.notitie #content {
	text-align: left;
}

.notitie #content a {
	color: #000;
	text-decoration: none;
}

.notitie h1 {
	margin-bottom: 0.25rem;
	color: #000;
	font-size: 24px;
}

.notitie ul {
	margin-top: 0;
	max-width: 70%;
}

.notitie h2 {
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 0;
	border-top: none;
	color: #000;
	font-weight: bold;
	font-size: 21px;
}

.notitie h3 {
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 0;
	border-top: none;
	color: #000;
	font-weight: bold;
	font-size: 18px;
}

.notitie h1 + h2 {
	padding-top: .5em;
}


h1.ks, h2.ks, h3.ks {
	margin-top: 0.5em;
	margin-bottom: 0;
	padding-top: 0.5em;
  border-top: 2px solid #81B3B7;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: bold;
}

h2.ks {
  font-size: 1.2em;
	margin-top: 1em;
	padding-bottom:.5em;
  border-top: 3px solid #81B3B7;
  border-bottom: 2px solid #81B3B7;
  margin-bottom:1em;
}

h3.ks {
  font-size: 1.1em;
	margin-top: 1em;
}

h2.ks + h3.ks {
	margin-top: 0.5em;
}

p.ks {
  margin-top: 0;
  color: #5A7B7E;
  border-bottom: 1px solid #81B3B7;
  padding-bottom: 0.5em;
  margin-bottom: 2em;
}

.ks.filter {
  padding-top: 0;
  background: transparent;
}

#toc {
	position: fixed;
	top: 5px;
	right: 5px;
	z-index: 99999;
	padding: 0.5em 2em 2em;
	background:rgba(255, 255, 255, 0.95);
	text-align: left;
	width: 16rem;
	border:1px solid #1e3c6f;
}

#toc a {
	cursor: pointer;
}

#toc a:focus {
	border-bottom-width: 2px;
	border-bottom-color: #1e3c6f!important;
}

#toc h2 {
	font-weight: bold;
	cursor: pointer;
}

#toc a[title=H2] {
	display: block;
	margin-top: 0.5em;
	border-bottom-color: transparent;
  font-weight: bold;
}

#toc a[title=H3] {
	margin-left: 1em;
	border-bottom-color: transparent;
}

#toc span {
	font-size: 2em;
  transform: rotate(-90deg);
  display: inline-block;
  top: 6px;
  position: relative;
  left: 6px;
  font-weight: normal;
}

#toc.active span {
  transform: rotate(90deg);
	left: 10px;
	top: 7px;
}

#link0 {
  display: none;
}

.toc-content {
	display: none;
}

.toc-content.active {
	display: block;
}
