/*Kleuren
Licht Blauwgroen  - #E5F0F0 - rgba(180,5,94,1)
Midden Blauwgroen - #CBE1E2 - rgba(183,10,89,1)  
Donker Blauwgroen - #81B3B7 - rgba(184,30,72,1)
Rusty Orange - #CF4B13 - rgba(18,91,81,1)
Donkerblauw - #2C3347 - rgba(224,38,28,1)  
*/
/* Dit is CSS voor het ontwerp, geen productie-CSS */

/* elementen */
* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    background-color: #fff;
    color: #2C3347;
    font: normal 1rem/1.4 titillium, arial, helvetica, sans-serif;
}

h1 {
    margin: 0 0 .5rem;
    font-weight: 600;
    font-size: 1.75rem;
    line-height: 1.2;
}

h2 {
    margin: 0 0 .25rem;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.3;
}

h3 {
    margin: 0 0 0.125rem;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.35;
}

h4 {
    margin: 0;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.4;
}

p, figcaption, table {
    margin: 0 0 1rem;

}

table caption {
    text-align: left;
    font-size: 1.125rem;
    line-height: 1.4;
    font-weight: 600;

}

a {
    color: #CF4B13;
}

div, picture, img {
    display: block;
    margin: 0 0 1rem;
    padding: 0;
}

img {
    width: 100%;
    height: auto;
}

/* a:visited {
	color:#993911; */
a:focus, a:hover {
    color: #993911;

}

a[href^="tel:"] {
    text-decoration: underline;
}

ul, ol {
    margin-top: 0;
    padding-left: 1.5rem;
}

ul li, ol li {
    margin-bottom: .35rem;
}

ol li {
    padding-left: .5rem;
}

fieldset {
    margin: 0 0 1rem;
    padding: 0;
    border: none;
}

form label {
    display: block;
    font-weight: 600;
    cursor: pointer;

}

input:not([type=checkbox]):not([type=radio]), textarea {
    display: inline-block;
    margin-bottom: 1rem;
    padding: .5rem;
    width: 100%;
    border: 1px solid #000;
    -webkit-border-radius: 0;
    -webkit-appearance: none;
}

select {
    margin-bottom: 1rem;
    padding: .5rem;
    width: 100%;
    border: 1px solid #000;
    padding: .5rem;
    -webkit-border-radius: 0;
    background-color: #fff;
}

input:focus, textarea:focus, select:focus {
    outline-color: #CBE1E2;
}

.search input:not([type=checkbox]):not([type=radio]), header input:not([type=checkbox]):not([type=radio]) {
    padding: .75rem;

}

input[type="checkbox"], input[type="radio"] {
    margin-right: .5rem;
}

label + input:not([type=checkbox]):not([type=radio]), label + textarea, label + select {
    margin-top: .25rem;
}

button {
    display: inline-block;
    margin-bottom: 1rem;
    padding: .5rem 2rem .5rem 1rem;
    border: none;
    background: #CF4B13 url(./img/icon-arrow-wit.svg) no-repeat center right 1rem / .45rem auto;
    color: #fff;
    text-decoration: none;
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
}

button:hover, button:focus {
    outline: none;
    background: #993911 url(./img/icon-arrow-wit.svg) no-repeat center right 1rem / .45rem auto;
    transition: .2s ease-in-out;
    color: #fff;
}

button.cta, button.cta:hover, button.cta:focus {
    padding: .5rem 1rem;
    background-image: none;
    color: #fff;
}

strong, b {
    font-weight: 600;
}

table {
    margin: 0 0 1rem;
    width: 100%;
    text-align: left;
}

table td, table th {
    display: table-cell;
    padding: .5rem 0;
    vertical-align: top;
}

table th {
    font-weight: 600;
}

table thead th {
    font-size: 1.125rem;
}

table tr {
    border-bottom: 1px solid #CBE1E2;
}

section {
    clear: both;
    display: block;
    margin: 0 0 1rem;
}

section + section {
    margin-top: 1.25rem;
}

/*header*/
header {
    margin: 1rem 0 0;
}

header .wrap {
    position: relative;
}

.logo {
    display: inline-block;
    margin-bottom: 1rem;
    width: 11rem;
}

@media screen and (min-width: 22.5rem) {
    .logo {
        display: inline-block;
        margin-bottom: 0;
        width: 14rem;
    }
}

.logo a {
    display: block;
}

.logo img {
    margin: 0;
}

/*footer*/
footer {
    margin-top: 2rem;
    padding: 2rem 0 1.5rem;
    background: #81B3B7;
}

.hulp + footer {
    margin-top: 0;
}

.social ul {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

.social ul li {
    display: inline-block;
    margin-right: .5rem;
}

.social li a {
    display: block;
    width: 2.25rem;
}

.social li.info{
    float:right; 
    position:relative; 
    right:65px; 
}

.social li.info a {
    color:#2c3347;
    text-decoration: none;
}

.social li.info a:hover {
    text-decoration: underline;
}

.tagline p {
    font-size: 1.25rem;
}

.social li a img {
    margin: 0;
}

/*menu*/
nav {
    position: relative;
    z-index: 1;
    display: block;
    background: #81B3B7;
    clear:both;
}

.menu ul {
    padding-left: 0;
    list-style: none;
}

.menu-state {
    display: none;
}

.menu-btn {
    display: block;
    padding: .5em 0;
    font-size: 1.25em;
    cursor: pointer;
}

.menu-state ~ .menu-btn:before {
    padding: .25rem 0 .25rem 3rem;
    width: 4rem;
    height: 4rem;
    background: url(./img/icoon-menu.svg) no-repeat left center / 2rem auto;
    content: 'menu';
    font-size: 1.25rem;
    cursor: pointer;
}

div.menu {
    overflow: hidden;
    margin: 0;
    max-height: 0;
    opacity: 0;
}

.menu-state:checked ~ div.menu {
    max-height: 999rem;
    background: #81B3B7;
    opacity: 1;
}

.menu-state:checked ~ .menu-btn:before {
    background: url(./img/icoon-kruis.svg) no-repeat left center / 2rem auto;
}

.menu ul {
    position: relative;
    display: block;
    margin-top: 1rem;
    color: #2C3347;
}

.menu .hoofd {
    margin-bottom: 1.5rem;
    font-size: 1.25rem;
    line-height: 1.35;
}

.menu ul li {
    margin-bottom: .5rem;
}

.hoofd li a {
    padding: .5rem 0;
    color: #2C3347;
}

.menu .sub {
    display: flex;
    font-size: .9rem;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.sub li:first-child {
    margin-bottom: .5rem;
    width: 100%;
}

.sub li {
    width: 40%;
    white-space: nowrap;
}

.sub li a {
    position: relative;
    color: #2C3347;
    text-decoration: none;
    font-weight: 600;
}

.sub li a .fa {
    position: absolute;
    right:100%;

    font-size:1.25rem;
    bottom:2px;
    margin-right:3px;
    color:#7CACAF;
}

.sub li a:hover, .sub li a:focus {
    text-decoration: underline;
}

.sub li a span {
    font-weight: 400;
}

.bewaar a span {
    position: absolute;
    top: .2rem;
    left: -1.7rem;
    color: #fff;
}

.aanvraaglijst a span {
    position: absolute;
    top: 0;
    left: -1.65rem;
    z-index: 10;
    color: #fff;
}

.aanvraaglijst a span:before {
    position: absolute;
    top: -.3rem;
    left: -.45rem;
    z-index: -10;
    width: 1.25rem;
    height: 1.25rem;
    border: 2px solid #81B3B7;
    border-radius: 50%;
    background: #2C3347;
    content: '';
}

.inlog {
    padding: .25rem .5rem .25rem 2.2rem;
    background: rgba(0, 0, 0, 0) url(./img/icoon-inlog-donker.svg) no-repeat left center / 1.4rem auto;
}

.publieksomgeving {
    padding: .25rem .5rem .25rem 2.2rem;
    background: rgba(0, 0, 0, 0) url(./img/icoon-bewaar-donker.svg) no-repeat left center / 1.4rem auto;
}

.bewaar {
    padding: .25rem .5rem .25rem 2.2rem;
    background: rgba(0, 0, 0, 0) url(./img/icoon-bewaar-donker.svg) no-repeat left center / 1.75rem auto;
}

.bewaar i {
    display: block;
    margin-top: -.2rem;
    font-style: normal;
}

.aanvraaglijst {
    padding: .3rem .5rem .3rem 3.2rem;
    background: rgba(0, 0, 0, 0) url(./img/icoon-aanvraaglijst-donker.svg) no-repeat left top .2rem / 2.1rem auto;
}

/*ingelogd*/
.ingelogd {
    padding: .25rem .5rem .25rem 2rem;
    background: rgba(0, 0, 0, 0) url(./img/icoon-ingelogd-donker.svg) no-repeat left .5rem top / 1.25rem auto;
}

.bewaardefile {
    padding: .25rem .5rem .25rem 2rem;
    background: rgba(0, 0, 0, 0) url(./img/icoon-bewaar-donker-dossier.svg) no-repeat left .5rem top / 1.5rem auto;
}

.aanvraaglijstplus {
    padding: .25rem .5rem .25rem 2rem;
    background: rgba(0, 0, 0, 0) url(./img/icoon-aanvraaglijst-plus-donker.svg) no-repeat left .2rem top / 1.75rem auto;
}

/*headerbeeld*/

.decor {
    margin-bottom: -1.25rem;
    padding-bottom: 0;
    width: 100%;
    height: 10rem;
    background-position: center center;
    background-size: cover;
}
/*
.decor.nonnen {
    background-image: url(./img/header-nonnen.jpg);
}

.decor.boeken {
    background-image: url(./img/header-boeken.jpg);
}

.decor.archief {
    background-image: url(./img/header-archief.jpg);
}

.decor.personen {
    background-image: url(./img/header-personen.jpg);
}

.decor.club {
    background-image: url(./img/header-club.jpg);
}

.decor.gebouwen {
    background-image: url(./img/header-gebouwen.jpg);
}
*/

/*zoek*/
.zoek {
    position: relative;
    z-index: -10;
    padding: 0;
    max-height: 0;
    opacity: 0;
}

.zoek-state {
    display: none;
}

.zoek button {
    position: absolute;
    top: 1.5rem;
    right: 0;
    z-index: 100;
    margin: 0;
    padding: .78rem 1.5rem;
    background-image: none;
    font-size: 1.125rem;
}

.zoek input:not([type=checkbox]):not([type=radio]) {
    max-width: 100%;
    border: 2px solid #CF4B13;
}

.zoek-btn {
    float: right;
    display: inline-block;
    width: 4rem;
    height: 4rem;
    background: #CF4B13;
    cursor: pointer;
    transition: .2s ease-in-out;
}

.zoek-btn:hover, .zoek-btn:focus {
    background-color: #993911;
}

.zoek-btn:before {
    position: absolute;
    width: 4rem;
    height: 4rem;
    background: url(./img/icoon-zoek.svg) no-repeat center center;
    content: '';
    cursor: pointer;
    transition: .3s ease-in-out;
}

.zoek-state:checked ~ .zoek-btn:before {
    background: url(./img/icoon-kruis-wit.svg) no-repeat center center / 2rem auto;
}

.zoek-state:checked ~ form.zoek {
    z-index: 10;
    padding: 1.5rem 0 .5rem;
    margin: 0;
    max-height: 999rem;
    background: #fff;
    opacity: 1;
}

.search-options {
    position: relative;
    display: block;
    margin: 0;
}

.intro .search-options {
    top: 1rem;
}

.search-options label {
    display: inline-block;
}

.search-options label input {
    margin-right: .35rem;
}

.search-options label {
    margin-right: 1rem;
}

.search-options p {
    position: relative;
    right: 0;
    display: inline-block;
    margin-top: .5rem;
}

.search-options p a {
    width: 100%;
    color: #2C3347;
}

/*wrap*/
.wrap {
    display: block;
    margin: 0 1.5rem;
}

/*algemene classes*/
.hidden {
    display: none;
}

.button {
    display: inline-block;
    padding: .5rem 2rem .5rem 1rem;
    border: none;
    background: #CF4B13 url(./img/icon-arrow-wit.svg) no-repeat top .95rem right 1rem / .45rem auto;
    color: #fff;
    text-decoration: none;
    font-weight: 600;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
}

.button:hover, .button:focus {
    background: #993911 url(./img/icon-arrow-wit.svg) no-repeat top .95rem right 1rem / .45rem auto;
    transition: .2s ease-in-out;
    color: #fff;
}

.button.cta {
    padding: .5rem 1rem;
    background-image: none;
    color: #fff;
    background-color: #CF4B13;
}

.button.cta:hover, .button.cta:focus {
    background: #993911;
}

.saved {
    display: inline-block;
    margin-right: 1rem;
    padding: .6rem 1rem .5rem;
    border: 1px solid #CF4B13;
    background: #fff;
    color: #CF4B13;
    font-weight: 600;
}

.saved {
    text-decoration: none;
}

.download {
    padding-right: 2.4rem;
    background-image: url(./img/icoon-download.svg);
    background-repeat: no-repeat;
    background-position: right 1rem center;
    text-decoration: none;
    font-weight: 600;
}

.download:hover, .download:focus {
    background-image: url(./img/icoon-download-hover.svg);
}

.button.download {
    padding-right: 2.6rem;
    background-image: url(./img/icoon-download-wit.svg);
    background-position: right 1rem center;
    background-size: 1rem auto;
    text-decoration: none;
    font-weight: 600;
}

.button.download:hover, .button.download:focus {
    background-color: #993911;
    color: #fff;
    padding-right: 2.6rem;
    background-image: url(./img/icoon-download-wit.svg);
    background-position: right 1rem center;
    background-size: 1rem auto;

}

.button.extern {
    padding: .5rem 2.6rem .5rem 1rem;
    background-image: url(./img/icoon-extern-wit.svg);
    background-position: right 1rem center;
    background-size: 1rem auto;
    text-decoration: none;
    font-weight: 600;
}

.button.extern:hover, .button.extern:focus {
    background-color: #993911;
    color: #fff;
}

.extern, .linklijst li a.extern {
    padding-top: 0;
    padding-right: 1.4rem;
    background-image: url(./img/icoon-extern.svg);
    background-position: right top .25rem;
    background-size: .9rem auto;
    background-repeat: no-repeat;
    font-weight: 400;
}

.extern:hover, .extern:focus {
    color: #993911;
}

.highlight {
    background: #E5F0F0;
}

.meer {
    display: inline-block;
    padding-right: 1rem;
    background: rgba(0, 0, 0, 0) url(./img/icon-arrow-orange.svg) no-repeat top .4rem right / .5rem auto;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
}

.intro {
    font-size: 1.125rem;
    /*18px*/
}

.intro h1 .meta {
    display: block;
    font-size: 1.125rem;

}

/*kruimel*/
.kruimel {
    position: relative;
    z-index: 10;
    padding: .95rem 0 .75rem;
    background: #CBE1E2;
}

.kruimel p {
    margin-bottom: 0;
}

.kruimel a {
    display: inline-block;
    margin-left: .5rem;
    padding-right: 1rem;
    background: rgba(0, 0, 0, 0) url(./img/icon-arrow-blauw.svg) no-repeat top .45rem right / .45rem auto;
    color: #2C3347;
    text-decoration: none;
}

.kruimel a:first-child {
    margin-left: 0;
}

.kruimel + .intro {
    margin-top: 2rem;
}

/*intro collectie*/
.intro.collectie {
    margin-top: -1rem;
    margin-bottom: 0;
    padding-bottom: 1rem;
    background: #CBE1E2;
}

.intro.collectie .form {
    position: relative;
    margin-bottom: 1rem;
}

.intro.collectie label {
    display: none;
}

.intro.collectie input:not([type=checkbox]):not([type=radio]) {
    margin: 0;
    border: none;
}

.intro.collectie button {
    position: absolute;
    top: 0;
    right: 0;
    width: 3.2rem;
    height: 3.2rem;
    background: #CF4B13;
    cursor: pointer;
}

.intro.collectie button:hover, .intro.collectie button:focus {
    background-color: #993911;
}

.intro.collectie button:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 3.2rem;
    height: 3.2rem;
    background: url(./img/icoon-zoek.svg) no-repeat center center;
    content: '';
    cursor: pointer;
}

.intro.collectie button span {
    display: none;
}

.intro.collectie.max p a {
    font-weight: 600;
    color: #2c3347
}

.intro.collectie.max h2 a:hover,
.intro.collectie.max h2 a:focus {
    text-decoration: none
}

.intro .search-options label {
    position: relative;
    z-index: 1000;
    display: inline-block;
}

.intro .search-options p {
    display: block;
}

/*tabs*/
.tabs {
    padding-left: 0;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 1.25rem;
}

.tabs li {
    display: inline-block;
    width: 100%;
    margin: 0;
}

.tabs li:first-child a {
    border-top: 1px solid #CBE1E2;

}

.tabs li a {
    display: block;
    margin-left: -.1rem;
    padding: .75rem 1rem .75rem;
    color: #2C3347;
    border-bottom: 1px solid #CBE1E2;
    text-decoration: underline;
    font-size: 1.125rem;
    margin: 0;
}

.tabs li a span {
    position: relative;
    padding-right: 1.25rem;
}

.tabs li a span:before {
    background: rgba(0, 0, 0, 0) url(./img/icon-arrow-down.svg) no-repeat top .7rem right / .75rem auto;
    top: 0;
    right: 0;
    position: absolute;
    width: 2rem;
    height: 2rem;
    content: '';
}

.tabs li a:hover, .tabs li a:focus {
    background: #81B3B7;
    transition: .2s ease-in-out;
    -webkit-font-smoothing: antialiased;
    color: #2C3347;
    text-decoration: none;
}

.tab-content {
    display: block;
}

/*hulp*/
.hulp {
    margin: 1.5rem 0 0;
    padding: 1.5rem 0 .5rem;
    background: #CBE1E2;
}

.hulp.linklijst a.extern {
    color: #2C3347;
}

.hulp.linklijst a span {
    color: #2C3347;
    font-size: 1rem;
}

.hulp.content {
    background: #fff;
}

/*linklijst*/
.linklijst ul {
    margin: 0 0 1.5rem;
    padding-left: 0;
    list-style: none;
}

.linklijst ul li {
    margin-bottom: .5rem;
}

.linklijst h2 {
    margin-bottom: .5rem;
}

.linklijst li a {
    padding-right: 1rem;
    padding-bottom: 0;
    background: rgba(0, 0, 0, 0) url(./img/icon-arrow-orange.svg) no-repeat top .6rem right / .6rem auto;
    text-decoration: underline;
}

.linklijst li a:hover, .lijklijst li a:focus {
    background: rgba(0, 0, 0, 0) url(./img/icon-arrow-orange-hover.svg) no-repeat top .6rem right / .6rem auto
}

.linklijst .extern {
    text-decoration: none;
}

.linklijst .extern:hover, .linklijst .extern:hover {
    background: rgba(0, 0, 0, 0) url(./img/icoon-extern.svg) no-repeat right top .25rem / .9rem auto;
}

.linklijst a span {
    text-decoration: underline;
    font-size: 1.25rem;
}

.linklijst a i {
    color: #2C3347;
    font-style: normal;
}

/*acties + aanvragen*/
.acties .wrap {
    background: #CBE1E2;
    padding: 1.5rem 1.5rem 1rem;
}

.feedback {
    display: none;
    margin-top: .5rem;
    margin-bottom: 0;
}

.aanvraag-state:checked ~ .feedback.aanvragen {
    display: block;

}

.aanvraag-state:checked ~ form {
    display: none;
}

.aanvraag-trigger, .bewaar-trigger {
    margin-bottom: 1rem;
    margin-top: 0;
}

.content .aanvraag-trigger {
    margin-top: 1rem;
}

.bewaar-state:checked ~ .feedback.bewaren {
    display: block;

}

.feedback.aanvragen p {
    margin-bottom: 0;

}

.acties .feedback p a {
    color: #2C3347;
    font-weight: 600;
}

.aanvraag-state, .bewaar-state {
    display: none;
}

.feedback.aanvragen + .feedback.bewaren {
    margin-top: 0
}

.aanvraag-state:checked ~ .aanvraag-trigger {
    background: #fff;
    border: 2px solid #CF4B13;
    color: #CF4B13;

}

.aanvraag-state ~ .aanvraag-trigger:before {
    content: 'Aanvragen';

}

.aanvraag-state:checked ~ .aanvraag-trigger:before {
    content: 'Stuk op aanvraaglijst';
    background: #fff url(./img/icoon-vink.svg) no-repeat right center / 1rem auto;
    padding-right: 1.5rem;

}

.bewaar-trigger, .aanvraag-trigger {
    position: relative;
    margin-bottom: .5rem;
}

.bewaar-state ~ .bewaar-trigger:before {
    content: 'Bewaar in dossier';

}

.bewaar-state:checked ~ .bewaar-trigger {
    background: #fff;
    border: 2px solid #CF4B13;
    color: #CF4B13;
}

.bewaar-state:checked ~ .bewaar-trigger:before {
    content: 'Stuk bewaard';
    background: #fff url(./img/icoon-vink.svg) no-repeat right center / 1rem auto;
    padding-right: 1.5rem;

}

/*delen*/
.delen {
    margin-top: 2rem;
}

.delen .wrap {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-wrap: wrap;
}

.delen h2 {
    display: inline-block;
    margin: 0 1rem .25rem 0;
    vertical-align: top;
    font-size: 1rem;
}

.delen ul {
    display: inline-block;
    margin: 0;
    padding-left: 0;
    list-style: none;
    vertical-align: top;
}

.delen li {
    display: inline-block;
    margin-right: .4rem;
}

.delen img {
    margin-bottom: 0;
    width: 2.25rem;
    height: auto;
}

/*tarieven*/
.tarieven {
    margin-top: -1rem;
}

.tarieven th {
    padding-top: 1rem;
}

.tarieven tr td:last-child, .tarieven thead th:last-child {
    width: 20%;
    text-align: right;
}

/*details*/
/*image*/
.image .wrap {
    position: relative;
}

.image img {
    height: auto;
}

.image.na figure {
    display: block;
    padding: 1.25rem 0 1rem;
    background: #E5F0F0;
    max-width: 30rem;
}

.image.na figcaption {
    margin-bottom: 0;
    color: #2C3347;
    text-align: center;
}

.image.na img {
    margin: 0 auto 1rem;
    max-width: 7rem;
}

.vergroot:before {
    top: 0;
    right: 0;
    position: absolute;
    width: 3rem;
    height: 3rem;
    background: #81B3B7 url(./img/icoon-zoek.svg) no-repeat center center;
    content: '';
    cursor: pointer;
}

.vergroot:hover:before, .vergroot:focus:before {
    background-color: #529297;
    transition: .2s ease-in-out;
}

/*resultaat*/
.resultaat {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
}

.resultaat article {
    position: relative;
    margin: 0 0 2rem;
    width: 100%;
}

.resultaat article p a {
    position: relative;
    display: block;
    margin-bottom: 1.5rem;
    padding: .75rem 1.25rem;
    max-width: 40rem;
    background: #E5F0F0;
    color: #2C3347;
    text-align: left;
    text-decoration: none;
    cursor: pointer;
}

.resultaat article span {
    font-weight: 600;
    font-size: 1.75rem;
}

.resultaat article b {
    position: absolute;
    bottom: -2rem;
    left: 0;
    display: block;
    padding: .5rem 1rem .5rem 1.25rem;
    width: 100%;
    background: #CF4B13 url(./img/icon-arrow-wit.svg) no-repeat center right 1.25rem/ .5rem auto;
    color: #fff;
    font-weight: 600;
    font-size: 1.25rem;
    -webkit-font-smoothing: antialiased;
}

.resultaat article b:hover {
    background: #993911 url(./img/icon-arrow-wit.svg) no-repeat center right 1.25rem / .5rem auto;
    transition: .2s ease-in-out;
}

/*resultaten*/

/*.multi-zoek {
	display: block;
    border: 2px solid #81B3B7;
    padding: 1rem;
    margin: -1.5rem 0 -1.5rem -0.1rem;
}*/
.resultaten ul {
    padding-left: 0;
    list-style: none;
}

.resultaten ul li h2 {
    font-size: 1.25rem;
}

.resultaten li a {
    padding-right: 1rem;
    background-image: url(./img/icon-arrow-orange.svg);
    background-repeat: no-repeat;
    background-position: top .6rem right;
    background-size: .6rem auto;
}

.resultaten li a:hover, .resultaten li a:focus {
    background-image: url(./img/icon-arrow-orange-hover.svg);
}

/*overzicht*/
.overzicht .wrap {
    position: relative;
}

.overzicht table {
    border-bottom: 2px solid #E5F0F0;
}

.overzicht thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
}

.overzicht table tr {
    border: none;
}

.overzicht table tbody tr:nth-child(odd) {
    background-color: #E5F0F0;
}

.overzicht tbody tr {
    display: block;
    padding: 1rem;
}

.overzicht th, .overzicht td {
    display: block;
}

.overzicht th {
    padding-bottom: .5rem;
    font-size: 1.125rem;
}

.overzicht th a {
    color: #2C3347;
}

.overzicht.bestellijst td {
    position: relative;
    padding-top: 1.25rem;
}

.overzicht td:last-child {
    padding-top: .25rem;
}

.overzicht.bestellijst tbody td:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 20rem;
    white-space: nowrap;
    font-weight: 600;
}

.overzicht.bestellijst label {
    display: block;
}

.overzicht.bestellijst tbody td:nth-of-type(1):before {
    content: "Details";
}

.overzicht.bestellijst tbody td:nth-of-type(2):before {
    content: "Archief";
}

.overzicht.bestellijst tbody td:nth-of-type(3):before {
    content: "Aanvraagtype";
}

.overzicht.bestellijst.dossier tbody td:nth-of-type(3):before {
    display: none;
}

.overzicht .soort {
    font-size: 1rem;
    font-weight: normal;
    display: block;
}

.overzicht p {
    display: block;
    padding: 1rem;
    background: #CBE1E2;
}

/*overzicht personen*/
.overzicht.personen thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
}

#items td.meta {
    padding: 0;
    text-align: left;
    font-weight: 400;
}

.overzicht.personen table tbody tr {
    background: #fff;
}

.overzicht.personen table tbody tr:nth-child(4n+1), .overzicht.personen table tbody tr:nth-child(4n+2) {
    background: #E5F0F0;
}

.overzicht.personen table tbody tr:nth-child(4n+1) .meta a:not(.button), .overzicht.personen table tbody tr:nth-child(4n+2) .meta a:not(.button) {
    color: #2C3347;
}

.overzicht.personen .scan {
    margin-top: .5rem;
    padding: 0 0 0 2rem;
    background: rgba(0, 0, 0, 0) url(./img/icoon-foto.svg) no-repeat center left / 1.5rem auto;
    font-style: italic;
}

.overzicht.personen tr {
    position: relative;
    padding: 1rem 1rem 1rem 2.25rem;
}

#items td .arrow {
    position: absolute;
    top: .75rem;
    left: .75rem;
    display: block;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

#items td .arrow:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 2rem;
    height: 2rem;
    content: url(./img/icon-arrow-down.svg);
}

#items .data:hover, #items .data:focus {
    background: #81b3b7;
    cursor: pointer;
}

#items td .arrow.up:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 2rem;
    height: 2rem;
    content: url(./img/icon-arrow-up.svg);
}

.personen .wrapper {
    position: relative;
    margin-top: 1rem;
    margin-right: 1.25rem;
    margin-bottom: 0;
    padding: 1rem 1rem .5rem;
    border: 1px solid #E5F0F0;
    background: #fff;
}

.personen .wrapper p {
    padding: 0;
    background: #fff;
}

.detail-state:checked ~ .wrapper {
    display: block;
}

.overzicht.personen td {
    padding: 0;
}

.overzicht.personen td:nth-of-type(2), .overzicht.personen td:nth-of-type(3) {
    display: inline-block;
    font-weight: 600;
}

/*toon*/
.toon-state {
    display: none;
}

.wrapper {
    display: none;
}

.toon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding-right: 1rem;
    background: rgba(0, 0, 0, 0) url(./img/icon-arrow-down.svg) no-repeat center right / .75rem auto;
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
}

.toon:before {
    content: 'Toon';
}

.toon-state:checked ~ .wrapper {
    display: block;
}

.toon-state:checked ~ .toon {
    background-image: url(./img/icon-arrow-up.svg);
}

.toon-state:checked ~ .toon:before {
    content: 'Verberg';
}

form p {
    margin-bottom: 0.5rem;
}

.toggle-naar, .toggle-hil {
    display: inline-block;
}

.hilversum, .naarden {
    display: none;
}

.state-hil:checked ~ .hilversum, .state-naar:checked ~ .naarden {
    display: block;
    margin-top: .5rem;
    margin-bottom: 0;
    margin-left: 1.5rem;
    padding: .5rem;
    background: #E5F0F0;
    max-width: 25rem;
}

h3.label + p {
    margin-top: -.125rem;
}

/*datepicker*/

.datepicker {
    position: relative;
    padding-right: 3.5rem;
    background: rgba(0, 0, 0, 0) url(./img/icoon-kalender.svg) no-repeat top .25rem right 0/ 2.75rem auto;
    max-width: 30rem;
    margin-bottom: 0;

}

label + .datepicker {
    margin-top: .25rem;
}

/*contact*/
.contact .note {
    font-size: .9rem;
}

.contact .highlight .meer {
    color: #2C3347;
    background: rgba(0, 0, 0, 0) url(./img/icon-arrow-blauw.svg) no-repeat top .4rem right / .5rem auto;
}

.intro b, .intro .mail {
    font-size: 1rem;
}

.highlight table tr {
    border-color: #fff;
}

/* .contact .locatie {
	padding-left:2rem; */
/* background:rgba(0,0,0,0) url(./img/icoon-locatie.svg) no-repeat left top  / .9rem auto; */
.contact thead th {
    font-size: 1rem;
}

.contact .extern {
    background-image: url(./img/icoon-extern-rood.svg);
}

.contact .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
}

.contact article {
    margin-bottom: .5rem;
    width: 100%;
}

.contact .highlight {
    padding: 1rem 1.5rem 0;
    background: #E5F0F0;
}

.contact img {
    margin: 0 0 1rem;
}

.contact h3 + p {
    margin-top: .5rem;
}

.tel {

    margin: 0 0 .25rem;
    padding: .25rem 0 .25rem 2rem;
    background: rgba(0, 0, 0, 0) url(./img/icoon-telefoon.svg) no-repeat left top .5rem / .9rem auto;
    color: #2C3347;
}

.mail {
    display: block;
    margin: 0;
    padding: .25rem 0 .25rem 2rem;
    background: rgba(0, 0, 0, 0) url(./img/icoon-mail.svg) no-repeat left top .6rem / 1rem auto;
}

/*gezocht*/
.gezocht {
    margin-top: 1rem;
}

.gezocht p {
    font-weight: 700;
    font-size: 1.25rem;
}

.gezocht span {
    display: inline-block;
    font-weight: 400;
    font-size: .85rem
}

/*selectie*/
.selectie ul {
    display: inline-block;
    padding-left: 0;
    list-style: none;
}

.selectie ul li {
    display: inline-block;
}

.selectie label input {
    display: none;
}

.selectie label {
    display: inline-block;
    padding: .5rem 2rem .5rem 1rem;
    border: none;
    background: #CBE1E2 url(./img/icoon-kruis.svg) no-repeat top .95rem right 1rem / .7rem auto;
    color: #2C3347;
    text-decoration: none;
    font-weight: 600;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
}

.selectie label:focus, .selectie label:hover {
    background-color: #81B3B7;
    transition: .3s ease-in-out;
}

.selectie p {
    display: inline-block;
    margin-right: 1rem;
}

/*details*/
.details {
    margin-top: 0;
}

.details td, .details th {
    display: block;
}

.details tr {
    border: none;
    padding: .25rem 0;
    display: block;
}

.details td, .details th {
    padding: 0;
}

/*pagination*/
.pagination {
    padding: 1rem 0;
    background: #CBE1E2;
}

.pagination .wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pagination p {
    margin: 0;
    width: 60%;
}

.pagination p a {
    display: inline-block;
    margin-right: .5rem;
    color: #2C3347;
}

.pagination p a:first-child {
    margin-left: .5rem;
}

.pagination p span {
    margin-right: .5rem;
    font-weight: 600;
}

.pagination i {
    display: inline-block;
    font-style: normal;
    padding: 0 .5rem;
}

.pagination ul {
    margin: 0;
    padding-left: 0;
    width: 40%;
    height: 2rem;
    list-style: none;
    text-align: right;
}

.pagination ul li {
    display: inline-block;
    margin: 0;
}

.pagination a span {
    display: none;
}

.pagination li a {
    display: block;
    width: 2rem;
    height: 2rem;
    background: #81B3B7;
}

.pagination .verder {
    background: #81B3B7 url(./img/icon-arrow-blauw.svg) no-repeat center center / .75rem auto;
}

.pagination .terug {
    background: #81B3B7 url(./img/icon-arrow-terug.svg) no-repeat center center / .75rem auto;
}

/*home*/
.home .intro.collectie {
    margin-top: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

/*collecties*/

.collecties p {
    background: #E5F0F0;
    font-size: 1.125rem;
    padding: .5rem;
    margin-top: -.5rem;
}

.collecties ul {
    padding-left: 0;
    list-style: none;
}

.collecties li a {
    position: relative;
    display: block;
    margin-top: -2.5rem;
    color: #2C3347;
    text-decoration: none;
}

.collecties li h2 {
    position: relative;
    top: 3.2rem;
    z-index: 100;
    display: inline-block;
    padding: .5rem;
    background: #CF4B13;
    color: #fff;
    -webkit-font-smoothing: antialiased;
}

.collecties li a:hover h2,
.collecties li a:focus h2 {
    background: #993911;
    transition: .2s ease-in-out;
}

.collecties li a img {
    margin-bottom: .5rem;
}

/*uitgelicht*/
.uitgelicht p {
    margin-bottom: 0;
}

.uitgelicht img {
    margin-bottom: .5rem;
}

.uitgelicht .wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.uitgelicht article {
    margin: .5rem 0 1rem;
    width: 100%;
}

/*inloggen*/
.inloggen a {
    display: block;
    margin-bottom: 1rem;
}

/*Filters*/
.filters {
    margin: 0 0 1rem;
    padding: 0;
    border-top: 2px solid #fff;
    background: #CBE1E2;
}

.filters .wrap {
    margin: 0;
}

.meer-state {
    display: none;
}

.meer-trigger {
    display: inline-block;
    cursor: pointer;
}

.meer-trigger:before {
    color: #CF4B13;
    content: 'Toon alle filters';
    text-decoration: underline;
}

.meer-wrap {
    margin-bottom: 0;
}

.meer-target {
    max-height: 0;
    font-size: 0;
    opacity: 0;
    transition: .25s ease;
}

.meer-state:checked ~ .meer-target {
    max-height: 999em;
    font-size: .95rem;
    opacity: 1;
}

.meer-state:checked ~ .meer-trigger {
    display: none;
}

.periode {
    display: flex;
    justify-content: flex-start;
    align-content: center;
}

.periode label {
    width: auto;
    display: inline-flex;
    margin: 0 .5rem;
}

.periode input:not([type=checkbox]):not([type=radio]) {
    width: 30%;
    display: inline-flex;

}

.periode img {
    display: none;
}

/* Accordion anchor */
.r-tabs .r-tabs-accordion-title .r-tabs-anchor {
    position: relative;
    display: block;
    padding: .75rem 1rem;
    color: #2C3347;
    text-align: center;
    cursor: pointer;
}

.r-tabs .r-tabs-accordion-title .r-tabs-anchor:hover, .r-tabs .r-tabs-accordion-title .r-tabs-anchor:focus {
    background-color: #81B3B7;
}

.r-tabs .r-tabs-accordion-title .r-tabs-anchor span {
    position: relative;
    left: 0;
    display: inline-block;
    margin: 0 auto;
    padding-right: 1.25rem;
}

.r-tabs .r-tabs-accordion-title .r-tabs-anchor span:before {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1000;
    width: 2rem;
    height: 2rem;
    background: rgba(0, 0, 0, 0) url(./img/icon-arrow-down.svg) no-repeat top .5rem right / .75rem auto;
    content: '';
}

/* Active accordion anchor */
.r-tabs .r-tabs-accordion-title.r-tabs-state-active .r-tabs-anchor span:before {
    background-image: url(./img/icon-arrow-up.svg);
}

/* Tab panel */
.r-tabs-panel {
    display: none;
    margin: 0;
    padding: 1rem 1.5rem .5rem;
    border-bottom: 2px solid #fff;
    background: #fff;
    background: #fff;
    list-style-type: none;
}

.r-tabs-panel ul li label {
    display: block;
    margin-bottom: .25rem;
    padding: .1rem 0;
    text-decoration: underline;
    cursor: pointer;
}

.r-tabs-panel div label {
    display: block;
    font-weight: normal;
    margin-bottom: .35rem;
    width: 100%;
}

.r-tabs-panel div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

/* Tabs container */
.r-tabs {
    position: relative;
    margin: 0;
    padding: 0;
    border-bottom: 2px solid #fff;
}

.resp-tabs-container {
    margin: 0;
}

.archieven ul {
    padding: 0;
    list-style: none;
}

.archieven ul li {
    padding: 1rem 1.5rem;
    list-style: none;
    border-bottom: 1px solid #CBE1E2;
}

.archieven table {
    margin-top: 0;
}

.archieven .wrap {
    margin: 0;
}

/*uitgebreid*/
.intro .search {
    position: relative;
}

.intro .search a.info {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
    display: inline-block;
    margin: 0 0 1rem;
    padding: .78rem 1.5rem;
    background-color: /* #CF4B13 */ #81B3B7;
    background-image: none;
    color: /* #fff */ #2C3347;
    text-decoration: none;
    font-weight: 600;
    font-size: 1.25rem;
}

.uitgebreid h3 {
    margin: .5rem 0 .25rem;
}

.uitgebreid button {
    margin-top: 2rem;
    font-size: 1.25rem;
}

.uitgebreid .search img {
    margin-top: .75rem;
}

.uitgebreid label {
    display: block;
    font-weight: 400;
}

/*accordion*/
.accordion {
    margin-bottom: 1.5rem;
}

.accordion_item {
    margin: 0 0 .35rem;
}

.accordion_item input {
    display: none;
}

.accordion_item label {
    position: relative;
    display: block;
    padding: 1rem 1rem 1rem 3.5rem;
    background: #E5F0F0;
    font-size: 1.25rem;
    font-weight: 600;
    cursor: pointer;
}

.accordion_item label h2 {
    display: inline-block;
    text-decoration: underline;
    font-size: 1.25rem;
}

.accordion label span {
    text-decoration: none;

}

.accordion_item label:hover, .accordion_item label:focus {
    background: #81b3b7;
}

.accordion_item div {
    overflow: hidden;
    margin: 0;
    max-height: 0;
    transition: max-height .25s;
    padding-left: 3.5rem;
    padding-top: 0;
    border: 1px solid #fff;
    background: #fff;
}

.accordion_item input:checked ~ div {

    max-height: 999rem;

}

.accordion_item label:after {
    position: absolute;
    top: 1.1rem;
    left: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 3px;
    background: #81b3b7 url(./img/icon-arrow-down.svg) no-repeat center center / 1rem auto;
    color: #2C3347;
    content: '';
    text-align: center;
    font-weight: 400;
    line-height: 1.125;
}

.accordion_item input:checked ~ label {
    background: #fff;
}

.accordion_item input:checked ~ label:after {
    background-color: #CBE1E2;
    background-image: url(./img/icon-arrow-up.svg);
}

.accordion h3 {
    font-size: 1.125rem;
}

.accordion h4 {
    font-size: 1rem;
}

span.highlight {
    background: #CBE1E2;
    font-weight: 600;
}

a span.highlight {
    background: none;
}

.accordion ul {
    padding-left: 0;
    list-style: none;
}

.accordion_item + p a.button {
    margin-top: 1.5rem;
}

/*boom*/
.boom li a {
    text-decoration: none;
}

.boom li a, .boom ul li a, .boom ul ul li a {
    display: flex;
    justify-content: flex-start;

}

.boom li b {
    margin-right: .5rem;
    width: 1rem;
    color: #2C3347;
    font-weight: 600;
}

.boom li span {
    text-decoration: underline;
}

.boom .open span {
    color: #2C3347;
    text-decoration: none;
    font-weight: 600;
    cursor: inherit;
}

.boom ul, .boom ul ul {
    padding-left: 2rem;
}

.boom li.laatst b {
    width: 2rem;
}

/*highlight*/
article.highlight {
    padding: 1rem 1.5rem;
}

/*resultaten uitgebreid*/

.resultaten.uitgebreid ul li {
    overflow: hidden;
    border-bottom: 2px solid #CBE1E2;
    padding: 1rem;

}

.resultaten.uitgebreid ul li div a {
    display: inline;
}

.resultaten.uitgebreid .type {
    font-size: 1.125rem;
    margin-bottom: 0;
}

.resultaten.uitgebreid .meta {

    margin-bottom: 0;
}

.resultaten.uitgebreid i {
    font-style: normal;
    background: #CBE1E2;
    font-weight: bold;
    display: inline-block;
    padding: 0 .15rem;
    margin: 0 -.1rem;
}

.resultaten.uitgebreid ul li div .button {
    background-image: url(./img/icon-arrow-wit.svg);
    padding-right: 2rem;
    display: inline-block;
    background-size: .45rem auto;
    background-position: top .95rem right 1rem;
    margin-top: .5rem;
}

.icoon {

    padding: 1.5rem 2.5rem;
    background: #E5F0F0;

}

.icoonarchief {

    padding: 1.5rem 2.5rem;
    background: #E5F0F0;

}

.resultaten .icoonarchief {
    display: none;
}

.resultaten .details {
    margin-bottom: 0.5rem;
}

.details-state {
    display: none;
}

.details-trigger {
    display: inline-block;
    cursor: pointer;
}

.details-trigger:before {
    color: #CF4B13;
    content: 'Meer details';
    text-decoration: underline;
    background-image: url(./img/icon-arrow-down-orange.svg);
    background-size: .7rem auto;
    background-position: right top .6rem;
    background-repeat: no-repeat;
    padding-right: 1rem;
}

.details-wrap {
    margin-bottom: 0;

}

.resultaten ul div div.details-wrap {
    float: none;
    margin: 0;
    width: auto;
}

.details-target {
    max-height: 0;
    font-size: 0;
    opacity: 0;
    transition: .25s ease;
}

.details-state:checked ~ .details-target {
    max-height: 999em;
    font-size: .95rem;
    opacity: 1;
}

.details-state:checked ~ .details-trigger {
    display: none;
}

/*resultaten kranten*/

.resultaten.kranten li a.thumbnail {
    padding-right: 0;
    background-image: none;
    max-height: 8em;
    overflow: hidden;
    display: block;
    margin-bottom: .5rem;
}

.resultaten.kranten li a.thumbnail img {
    max-width: 200px;
}

.resultaten.kranten ul li {
    overflow: hidden;
    border-bottom: 2px solid #CBE1E2;
    padding: .5rem 0;
}

.resultaten.kranten li a.thumbnail img.icoon {

    padding: 1rem 1.5rem;
}

@media screen and (min-width: 42.5rem) {
    .uitgebreid .container {
        columns: 3 10vw;
    }

    .uitgebreid .container label {
        white-space: nowrap;
    }

    .hulp.linklijst .wrap {
        display: flex;
        justify-content: space-between;
        padding-right: 0;
    }

    .hulp.linklijst article {
        width: 60%;
    }

    .hulp.linklijst article:last-child {
        width: 30%;
    }

    /*resultaten uitgebreid*/
    .resultaten.uitgebreid img {
        float: left;
        width: 40%;
        max-width: 200px
    }

    .resultaten.uitgebreid ul div {
        float: left;
        width: 55%;
        margin-left: 5%;
    }

    /*resultaten kranten*/
    .resultaten.kranten ul li {
        margin-bottom: 0;
        padding: 1rem 0;
    }

    .resultaten.kranten li a.thumbnail {
        float: left;
        width: 15%;
        max-width: 200px;
        background-image: none;
        padding-right: 0;
        max-height: 9999rem;
        overflow: visible;
        margin-bottom: 0;

    }

    .resultaten.kranten li a.thumbnail img {
        max-width: 150px;
        margin-bottom: 0;
    }

    .resultaten.kranten ul div {
        float: left;
        width: 75%;
        margin-left: 10%;
    }

    .resultaten .details {
        margin-top: .5rem;
    }

    .resultaten .details td, .resultaten .details th, .beeldbanklijst .details th, .beeldbanklijst .details td {
        padding: 0;
    }

    .resultaten .details th {
        width: 25%;
    }

    .resultaten .icoonarchief {
        display: block;
    }

    /*tabs*/
    .tabs {
        margin-bottom: 0;
        justify-content: flex-start;
    }

    .tabs li {
        width: auto;
        margin-right: 4px;
    }

    .tabs li:last-child {
        border: none;
    }

    .tabs li a {
        position: relative;
        z-index: 10;
        background: #CBE1E2;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .tabs li a span {
        padding-right: 0;
    }

    .tabs li a span:before {
        background-image: none;
    }

    .tabs li.active a {
        position: relative;
        z-index: 1000;
        background: #fff;
        text-decoration: none;
        font-weight: 700;
        -webkit-font-smoothing: antialiased;
        border: 2px solid #81B3B7;
        border-bottom: none;

    }

    .tab-content {
        position: relative;
        z-index: 100;
        border: 2px solid #81B3B7;
        padding: 1.25rem 1.5rem .5rem;
        margin-top: -2px;

    }

    .tab-content .resultaat h2, .tab-content .resultaten h2 {
        display: none;
    }

}

/* Accordion responsive breakpoint */
@media only screen and (max-width: 42.5rem) {
    .r-tabs .r-tabs-nav {
        display: none;
    }

    .r-tabs .r-tabs-accordion-title {
        display: block;
        margin: 0;
        border-bottom: 2px solid #fff;
    }

}

@media only screen and (min-width: 59rem) {
    .archieven .wrap {
        margin: 0 auto;
        max-width: 58rem;
    }

    .intro.collectie button span {
        display: block;
    }

    .intro.collectie button {
        width: auto;
        font-size: 1.25rem;
    }

    .intro.collectie button:before {
        background-image: none;
    }

    .resultaten.uitgebreid .wrap {
        padding-right: 0;
    }

    .acties .wrap {
        padding-right: 10rem;
    }

    #items td .arrow.up:before {
        left: 1rem;
    }
}

/*560px - pas vooral aan indien nodig!*/
@media screen and (min-width: 35rem) {
    .zoek {
        margin: 0 -1rem;
    }

    .zoek-state:checked ~ form.zoek {
        padding: 1.5rem 0 1.25rem 0;
    }

    .zoek-btn:before {
        z-index: 1000;
    }

    .search-options {
        display: table;
        width: 100%;
    }

    .search-options label, .search-options p {
        display: table-cell;
    }

    .search-options label {
        padding-right: .75rem;
        white-space: nowrap;
    }

    .search-options p {
        margin-top: -1.7rem;
        width: 100%;
        text-align: right;
    }

    .inlog, .bewaar, .aanvraaglijst {
        padding: 0;
    }

    .sub li {
        padding: .25rem .5rem .25rem 2.25rem;
    }

    .aanvraaglijst a span:before {
        top: -.2rem;
        left: -0.3rem;
        border-color: #fff;
        background: #CF4B13;
    }

    .aanvraaglijst a span {
        top: -.3rem;
        left: -2.75rem;
    }

    .uitgebreid .container {
        columns: 3 10vw;
    }

    /*collecties*/
    .collecties p {
        background: #E5F0F0;
        font-size: 1.125rem;
        padding: .5rem;
        margin-top: -.5rem;
    }

    /*bewaar*/
    .bewaar a span {
        top: 0rem;
    }

    /*beeldbank*/
    .beeldbank.resultaten ul {
        columns: 2 10vw;
    }

    .beeldbank.resultaten ul li {
        background: #CBE1E2;
        break-inside: avoid;
    }

    .beeldbank.resultaten ul li h2 {
        font-size: 1rem;
        color: #2C3347;
        padding: 0 5rem 1rem 1rem;
    }

    .beeldbank.resultaten li a {
        padding-right: 0;
        background: none;
        text-decoration: none;
    }

    .beeldbank.resultaten li div {
        position: relative;
    }

    .deelcollectie {
        position: absolute;
        max-width: 4rem;
        float: right;
        top: .1rem;
        right: 0.5rem;
    }

}

/*680px - pas vooral aan indien nodig!*/
@media screen and (min-width: 42.5rem) {
    /*elementen*/
    section:not(.intro) .wrap input:not([type=checkbox]):not([type=radio]), textarea, select {
        max-width: 30rem;
    }

    /*menu*/
    .menu .hoofd .active li a {
        display: inline-block;
    }

    /*gezocht*/
    .gezocht span {
        padding-left: 0.5rem;
    }

    /*resultaat*/
    .resultaat {
        margin-bottom: 0;
        margin-top: 1rem;
    }

    .resultaat article {
        margin-bottom: 3rem;
        width: 48%;
    }

    .resultaat article:last-child, .resultaat article:nth-child(5) {
        margin-bottom: 0;
    }

    .overzicht.bestellijst th, .overzicht.bestellijst td, .overzicht.bestellijst td:last-child {
        display: table-cell;
        padding: 1.25rem 1rem;
        width: 35%;
    }

    .overzicht.bestellijst td:last-child {
        width: 20%;
        text-align: right;
        padding-right: 2rem;
    }

    .overzicht.bestellijst tbody tr {
        display: table-row;
    }

    .overzicht.bestellijst tbody td:before {
        display: none;
    }

    .overzicht.bestellijst thead tr {
        position: relative;
        top: 0;
        left: 0;
        background: #CBE1E2;
    }

    /*filters*/
    .filters {
        background: linear-gradient(to bottom, #CBE1E2 3.5rem, #fff 3.5rem, #fff 3.5rem);
    }

    .filters .wrap {
        margin: 0 auto;
    }

    .r-tabs {
        padding: 0;
        border-bottom: none;
    }

    .r-tabs-anchor {
        display: none;
    }

    /*responsive tabs*/
    .r-tabs .r-tabs-nav {
        display: flex;
        margin: 0;
        padding: 0;
        width: 100%;
        list-style-type: none;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .r-tabs .r-tabs-tab {
        margin: 1px 0 1px 0;
        flex-grow: 1;
        height: 2rem;
        border-right: 2px solid #fff;
        border-bottom: none;
    }

    .r-tabs .r-tabs-tab:first-child {
        border-left: 2px solid #fff;
    }

    .r-tabs .r-tabs-panel {
        display: none;
        margin: 2px;
    }

    .r-tabs .r-tabs-accordion-title {
        display: none;
    }

    .r-tabs .r-tabs-panel.r-tabs-state-active {
        display: block;
    }

    /* Tab element */
    .r-tabs .r-tabs-nav .r-tabs-tab {
        position: relative;
        height: auto;
    }

    /* Tab anchor */
    .r-tabs .r-tabs-nav .r-tabs-anchor {
        position: relative;
        display: block;
        padding: 1rem;
        background: #CBE1E2;
        color: #2C3347;
        text-align: center;
        text-decoration: none;
    }

    .r-tabs .r-tabs-nav .r-tabs-anchor:hover, .r-tabs .r-tabs-nav .r-tabs-anchor:focus {
        background-color: #81B3B7;
    }

    .r-tabs .r-tabs-nav .r-tabs-anchor span {
        position: relative;
        display: inline-block;
        margin: 0 auto;
        padding-right: 1.25rem;
    }

    .r-tabs .r-tabs-nav .r-tabs-anchor span:before {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1000;
        width: 2rem;
        height: 2rem;
        background: rgba(0, 0, 0, 0) url(./img/icon-arrow-down.svg) no-repeat top .5rem right / .75rem auto;
        content: '';
    }

    /* Active state tab anchor */
    .r-tabs .r-tabs-nav .r-tabs-state-active .r-tabs-anchor {
        background: #81B3B7;
    }

    .r-tabs .r-tabs-nav .r-tabs-state-active .r-tabs-anchor span:before {
        background: url(./img/icon-arrow-up.svg) no-repeat top .5rem right / .75rem auto;
    }

    .r-tabs-panel div label {
        width: 33.333%;
    }

    .r-tabs-panel div label.meer-trigger {
        width: 100%;
    }

    .meer-wrap {
        margin-bottom: 1rem;
    }

    .filters .periode img {
        display: block;
    }

    section.filters .periode input:not([type=checkbox]):not([type=radio]) {
        max-width: 4rem;
    }

    /*linklijst .onderzoekpagina*/
    .onderzoekpagina ul {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .onderzoekpagina li {
        position: relative;
        margin-top: 0;
        padding: 5px 5px 5px 0;
        max-width: 45%;
    }

    /*uitgelicht*/
    .uitgelicht {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .uitgelicht h2 {
        display: block;
        margin: 10px 60% 0 0;
    }

    .uitgelicht article {
        position: relative;
        margin-top: 10px;
        padding: 5px;
        max-width: 30%;
    }

    /*beeldbank*/
    .beeldbank.resultaten .wrap {
        padding-right: 0;
    }

    .beeldbank.resultaten ul {
        columns: 3 10vw;

    }

    .beeldbank.resultaten ul li {
        background: #CBE1E2;
        break-inside: avoid;
    }

    .beeldbank.resultaten ul li h2 {
        font-size: 1rem;
        color: #2C3347;
        padding: 0 5rem 1rem 1rem;
    }

    .beeldbank.resultaten li a {
        padding-right: 0;
        background: none;
        text-decoration: none;
    }

    .beeldbank.resultaten li div {
        position: relative;
    }

    .beeldbank.resultaten li:hover, .beeldbank.resultaten li:focus {
        background: #81B3B7;
    }

    .deelcollectie {
        position: absolute;
        max-width: 4rem;
        float: right;
        top: .1rem;
        right: 0.5rem;
    }

    .icooninfoto li a {
        width: 87%;

    }

    .icooninfoto li div {
        width: 87%;
        margin-left: 0rem;
    }

    /*contact*/
    .contact article {
        margin: 1.5rem 0 1rem;
        width: 46%;
    }

    .contact .highlight {
        padding: 1.5rem 2em 1rem;
    }

    .contact .intro {
        margin-bottom: 1rem;
    }

    .contact img {
        margin-bottom: 0;
    }

    .contact article {
    }

    /*image*/
    .image .wrap .onlinescan {
        position: relative;
        margin-left: 0;
        max-width: 30rem;
    }

    .imgrechts {
        float: right;
        max-width: 45%;
        margin-left: 1.5rem;
    }

    /*collecties*/
    .collecties ul {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .collecties li {
        position: relative;
        padding: 5px;
        max-width: 46%;
    }

    /*details*/
    .details td, .details th {
        display: table-cell;
        padding: 0.15rem 0.5rem .15rem 0;
    }

    .details tr {
        padding: 0;
        display: table-row;
    }

    .details tbody tr th {
        width: auto;
    }

}

/*768px!*/
@media screen and (min-width: 48rem) {

    .zoek-state:checked ~ form.zoek {
        top: -5.5rem;
        padding: 1.5rem 1rem 1.25rem 1rem;
        margin: 0 5.5rem -5.5rem 3.75rem;
    }

    .zoek button {
        right: 1rem
    }

}

/*950px!*/
@media screen and (min-width: 59rem) {

    .zoek-state:checked ~ form.zoek {
        top: -5.8rem;
        padding: 1.5rem 1.5rem 1.25rem 1rem;
        margin: 0 5.5rem -5.8rem 4.65rem;
    }

    .zoek button {
        right: 1.5rem
    }

    #items td .arrow:before {
        left: 1rem
    }

    /*archieven*/
    .archieven .wrap {
        margin: 0 auto;
        max-width: 58rem;
    }

    .intro.collectie button span {
        display: block;
    }

    .intro.collectie button {
        width: auto;
        font-size: 1.25rem;
        padding: .5rem 2rem;
    }

    .intro.collectie button:before {
        background-image: none;
    }

    /*home*/
    .home .intro.collectie button {
        width: 8.5rem;
    }

    .home .intro.collectie {
        margin-top: 0;
        padding-top: 2.5rem;
        padding-bottom: 1.5rem;
    }

    /*overzicht*/
    /*overzicht*/
    .overzicht.personen thead tr {
        position: relative;
        top: 0;
        left: 0;
        background: #CBE1E2;
    }

    .overzicht.personen tbody tr {
        display: table-row;
    }

    .overzicht thead th {
        padding: .5rem 1rem;
        font-size: 1.125rem;
    }

    .overzicht.personen td:nth-child(1) {
        width: 20%;
    }

    .overzicht.personen td:last-child {
        width: 10%;
        text-align: right;
    }

    .overzicht.personen thead tr {
        position: relative;
        top: 0;
        display: table-row;
        padding-left: 2.25rem;
        width: 100%;
    }

    .overzicht.personen tr {
        min-height: 2rem;
    }

    #items td.meta {
        padding: 0.5rem 1rem 1.25rem 5.5rem;
    }

    .overzicht.personen th, .overzicht.personen td, .overzicht.personen td:nth-of-type(1), .overzicht.personen td:nth-of-type(2) {
        display: table-cell;
        padding: .75rem 1rem;
    }

    .overzicht.personen td:first-child, .overzicht.personen td:last-child {
        width: 4.5rem;
    }

    .overzicht.personen .scan {
        background-position: center center;
    }

    .overzicht.personen .uitklap:before {
        background-position: top .5rem center;
    }

    .overzicht.personen thead th {
        padding: .5rem 1rem;
        font-size: 1.125rem;
    }

    .overzicht.personen td, .overzicht.personen th {
        position: relative;
    }

    .overzicht.personen td.scan span {
        display: none;
    }

    .overzicht.personen td:last-child {
    }

    .overzicht.personen tbody td:before {
        display: none;
    }

    /*wrap*/
    .wrap {
        position: relative;
        overflow: visible;
        margin: 0 auto;
        max-width: 55rem;
    }

    header .wrap {
        margin: 0 auto;
    }

    wrap .intro, wrap .tabswrap, wrap .footer, wrap .nav {
        margin: 0 25rem;
        max-width: 50rem;
    }

    .max .wrap {
        max-width: 55rem;
    }

    .intro.collectie .wrap {
        padding-right: 0;
    }

    .intro .wrap, .tabcontent .wrap, .hulp .wrap, .content .wrap, .resultaten .wrap, .uitgebreid .wrap, .onderzoekpagina .wrap {
        padding-right: 10rem;

    }

    /*menu*/
    .menu-btn {
        display: none;
    }

    div.menu {
        max-height: 999rem;
        opacity: 1;
    }

    .menu .hoofd {
        display: flex;
        margin-bottom: .5rem;
        justify-content: space-between;
        flex-wrap: nowrap;
    }

    .menu .hoofd .active {
        border-bottom: 10px #CBE1E2 solid;
        margin-bottom: -8px;
    }

    .menu .hoofd li {
        display: block;
        text-align: center;
    }

    .menu .hoofd li a {
        text-decoration: none;
    }

    .zoek-state:checked ~ .menu .sub {
        top: -13.5rem;
    }

    .inlog {
        padding: .25rem .5rem .25rem 2rem;
        background: rgba(0, 0, 0, 0) url(./img/icoon-inlog.svg) no-repeat left .5rem top / 1.25rem auto;
    }

    .bewaar {
        padding: .25rem .5rem .25rem 2rem;
        background: rgba(0, 0, 0, 0) url(./img/icoon-bewaar.svg) no-repeat left .5rem top / 1.5rem auto;
    }

    .bewaar a span {
        top: -.1rem;
        left: -1.3rem;
    }

    .aanvraaglijst {
        padding: .25rem .5rem .25rem 2rem;
        background: rgba(0, 0, 0, 0) url(./img/icoon-aanvraaglijst.svg) no-repeat left .2rem top / 1.75rem auto;
    }

    /*INGELOGDE ICOONTJES*/
    .ingelogd {
        padding: .25rem .5rem .25rem 2rem;
        background: rgba(0, 0, 0, 0) url(./img/icoon-ingelogd.svg) no-repeat left .5rem top / 1.25rem auto;
    }

    .bewaardefile {
        padding: .25rem .5rem .25rem 2rem;
        background: rgba(0, 0, 0, 0) url(./img/icoon-bewaar-dossier.svg) no-repeat left .5rem top / 1.5rem auto;
    }

    .aanvraaglijstplus {
        padding: .25rem .5rem .25rem 2rem;
        background: rgba(0, 0, 0, 0) url(./img/icoon-aanvraaglijst-plus.svg) no-repeat left .2rem top / 1.75rem auto;
    }

    .sub li a {
        margin-right: .5rem;
        color: #CF4B13;
    }

    .logo img {
        margin: -.6rem 0 0;
        width: 120%;
    }

    .menu .sub {
        position: absolute;
        top: -5.7rem;
        right: 3.5rem;
        flex-wrap: nowrap;
        align-items: flex-start;
    }

    .sub li:first-child {
        margin-right: .5rem;
        margin-bottom: .5rem;
        width: auto;
    }

    .sub li {
        margin-right: 1rem;
        vertical-align: top;
        white-space: nowrap;
    }

    .kruimel {
        padding: .95rem 0 1.2rem;
    }

    /*aanvragen*/
    .aanvragen {
        max-width: 55rem;
        margin: 1rem auto 0;
        padding-right: 10rem;
    }

    .aanvragen + .delen .wrap {
        padding-right: 10rem;
    }

}

/*1100px*/
@media screen and (min-width: 68.75rem) {

    header .wrap {
        margin: 0 auto;
        max-width: 64.5rem;
    }

    .menu .sub {
        right: -1rem;
    }

    .rechts {
        right: -2.5rem;
    }

    .links {
        left: -2.5rem;
    }

    .collecties li a {
        margin-top: 1rem;
    }

    .collecties li h2 {
        position: absolute;
        top: 9rem;
    }

    .collecties li:nth-child(1) {
        padding-top: 4rem;
    }

    .collecties li:nth-child(2) {
        /* padding-top: 4rem; */
    }

    .collecties li:nth-child(3) {
        right: 3rem;
        margin-top: -.5rem;
    }

    .collecties li:nth-child(4) {
        top: -4rem;
        left: 3rem;
    }

    .collecties li:nth-child(5) {
        top: -2rem;
    }

    .collecties li:nth-child(6) {
        margin-top: -4rem;
    }
}

/*1300px*/
@media screen and (min-width: 81.25rem) {
    .collecties .wrap {
        max-width: 64.5rem;
        margin-top: 1.75rem;
        margin-bottom: -3rem;
    }

    .collecties li {
        position: relative;
        padding: 5px;
        max-width: 30%;
    }

    .collecties p {
        font-size: 1rem;
    }

    .collecties li h2 {
        top: 6rem;
    }

    .collecties li:nth-child(1) {
        padding-top: 0;
    }

    .collecties li:nth-child(2) {
        padding-top: 3rem;
    }

    .collecties li:nth-child(3) {
        right: -2rem;
        margin-top: -.5rem;
    }

    .collecties li:nth-child(4) {
        top: -4rem;
        left: -2.5rem;
    }

    .collecties li:nth-child(5) {
        top: -1rem;
        right: -2rem;
    }

    .collecties li:nth-child(6) {
        margin-top: -4rem;
        right: -4rem;
    }
}
