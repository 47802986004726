@media all {
  @import "./fonts";

}

@media screen {

  .sr-only {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
  }

  @import "./normalize";
  @import "./scherm";
  @import "./scherm-02";

  .kruimel.yoast-kruimel a {
    /*padding-right: 0;*/
    /*background-image: none;*/
  }


  /* The wordpress login link */
  #loginlink {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 1em;
    background: #fff;
    opacity: 0;

    &:hover {
      opacity: 1;
    }
  }


  /* JS progressive enhancement */
  .js .updated-in-atlantis {
    transition: .5s;

    &:not(.updated) {
      opacity: .5;
      pointer-events: none;
    }
  }
}

